import loader from "./images/earth.gif";
import oneLineLogo from "./images/oneLineLogo.png";
import theDecentralHub from "./images/TheDecentralHub.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src={theDecentralHub}
          className="App-logo"
          alt="The Decentral HuB"
        ></img>

        <img src={loader} className="App-loader" alt="logo" />
        <img
          src={oneLineLogo}
          className="oneLine"
          alt="Only Illusions Create Boundaries"
        />

        <div className="bkg-glow"></div>

        <div class="bgimg">
          <div class="middle">
            <h1>COMING SOON</h1>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
